<template>
  <div>
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start my-2 mb-md-0"
          >
            <label>Show</label>
            <v-select
            data-testid="page-size"
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refCourseListTable"
        class="position-relative"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :items="eventsList"
        select-mode="multi"
        :sort-by.sync="sort"
        selectable
        show-empty
        empty-text="No matching records found"
        @row-selected="onRowSelected"
      >
        <!-- Column: Customer -->
        <template #cell(customer)="data">
          <div>{{ data.item.user.display_name }}</div>
        </template>

        <!-- Column: Ticket Summary -->
       <template #cell(details)="data">
  <div class="d-flex align-items-center">
    <img :src="data.item.user.avatar" class="rounded-circle mr-2" width="25" height="25" alt="avatar" />
    <div>
      <div class="d-flex align-items-center">
        <span class="badge badge-primary mr-2">{{ data.item.tags[0].display_name }}</span>
        <strong>{{ data.item.subject }}</strong>
      </div>
      <div>{{ data.item.latest_reply.body }}</div>
    </div>
    <b-badge variant="" class="mx-2 text-black">{{ data.item.replies_count }}</b-badge>
  </div>
</template>
        <!-- Column: Number -->
       

        <!-- Column: Last Updated -->
        <template #cell(last_updated)="data">
          <div>{{ formatDate(data.item.updated_at) }}</div>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <a
          
          >
            <router-link :to="`/show-ticket/${data.item.id}`" style="margin: 0px 12px">
              <feather-icon :data-testid="`show-item-${data.item.id}`" icon="EyeIcon" />
            </router-link>
          </a>
        </template>
      </b-table>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">
              Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries
            </span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalevents"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>

    <!-- Reply Modal -->
    <b-modal id="reply-modal" v-model="isReplyModalVisible" title="Reply to Ticket" hide-footer>
      <validation-observer ref="replyFormValidate">
        <b-form @submit.prevent="submitReply">
          <b-row>
            <b-col md="12">
              <b-form-group label="Body" label-for="reply-body" class="mb-2">
                <validation-provider #default="{ errors }" name="Body" rules="required">
                  <quill-editor
                    ref="replyContentFormEditor"
                    v-model="replyForm.body"
                    :options="getEditorOptions('replyContentFormEditor')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12" class="mt-50">
              <b-button
                v-if="!isReplyLoading"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-1"
                @click="submitReply"
              >
                Submit Reply
              </b-button>
              <b-button v-if="isReplyLoading" variant="primary" disabled class="mr-1">
                Loading...
                <b-spinner small />
                <span class="sr-only" />
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BModal,
  BPagination,
  BSpinner,
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import { ref, watch, computed, reactive } from '@vue/composition-api';
import Vue from 'vue';
import store from '@/store';
import { quillEditor } from 'vue-quill-editor';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import axios from 'axios';

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BModal,
    BPagination,
    BSpinner,
    vSelect,
    quillEditor,
    ValidationProvider,
    ValidationObserver,
  },
  setup() {
    const eventsList = ref([]);
    const perPageOptions = [25, 50, 100, 200];
    const perPage = ref(25);
    const totalevents = ref(0);
    const currentPage = ref(1);
    const searchQuery = ref('');
    const sort = ref('');
    const SortArray = [];
    const refCourseListTable = ref(null);
    const selectedRow = ref([]);
    const isReplyModalVisible = ref(false);
    const replyFormValidate = ref(null); // Define the reply form validation reference
    const replyForm = ref({ body: '', uploads: [], status: 'pending' });
    const replyUploadedFiles = ref([]);
    const isReplyLoading = ref(false);

    const getevents = () => {
      let data = null;
      if (!SortArray.includes(sort.value) && sort.value !== '') {
        SortArray.push(sort.value);
        data = SortArray.toString().replace('[', '');
      }

      const query = {
        sort: data,
        perPage: perPage.value,
        page: currentPage.value,
        loader: 'ticketTable'
      };

      if (searchQuery.value) {
        query['query'] = searchQuery.value;
      }

      store
        .dispatch('tickets/Alltickets', query)
        .then(response => {
          eventsList.value = response.data;
        });
    };

    const onRowSelected = items => {
      selectedRow.value = items;
    };

    const openReplyModal = ticketId => {
      replyForm.value.ticket_id = ticketId;
      isReplyModalVisible.value = true;
    };

    const handleReplyFileUpload = async event => {
      const files = event.target.files;
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const formData = new FormData();
        formData.append('file', file);
        const token = localStorage.getItem('SupportToken');
        try {
          const response = await axios.post('https://support.prokoders.work/client/file-entries', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
              'Authorization': `Bearer ${token}`,
            },
          });
          replyUploadedFiles.value.push({ id: response.data.fileEntry.id, name: file.name });
          replyForm.value.uploads.push(response.data.fileEntry.id);
        } catch (error) {
          console.error('Error uploading file:', error);
        }
      }
    };

    const submitReply = () => {
      replyFormValidate.value.validate().then(success => {
        if (success) {
         
          isReplyLoading.value = true;
          store.dispatch('tickets/replyTicket', replyForm.value)
            .then(response => {
              isReplyLoading.value = false;
              Vue.swal({
                title: 'Reply Sent',
                text: 'Your reply has been sent successfully.',
                icon: 'success',
                confirmButtonText: 'Ok',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              });
              isReplyModalVisible.value = false;
              getevents();
            })
            .catch(error => {
              isReplyLoading.value = false;
              Vue.swal({
                title: 'Error',
                text: 'There was an error sending your reply. Please try again.',
                icon: 'error',
                confirmButtonText: 'Ok',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              });
            });
        }
      });
    };

    const markAsResolved = () => {
      const ticketIds = selectedRow.value.map(row => row.id);
      axios.post('https://support.prokoders.work/client/tickets/status/change', {
        ids: ticketIds,
        status: 'closed'
      }, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('SupportToken')}`
        }
      })
        .then(response => {
          Vue.swal({
            title: 'Tickets Resolved',
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          });
          getevents();
        })
        .catch(error => {
          Vue.swal({
            title: 'Error',
            text: 'There was an error resolving the tickets. Please try again.',
            icon: 'error',
            confirmButtonText: 'Ok',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          });
        });
    };

    const statusClass = status => {
      return {
        'bg-blue text-white': status === 'open',
        'bg-green text-white': status === 'resolved',
        'bg-grey text-white': status === 'closed',
      };
    };

    const buttonVariant = status => {
      return status === 'open' ? 'outline-primary' : 'outline-secondary';
    };

    const tableColumns = [
      { key: 'customer', label: 'Customer' },
      { key: 'details', label: 'Details' },
   
      { key: 'id', label: 'Number' },
      { key: 'last_updated', label: 'Last Updated' },
      { key: 'actions', label: 'Actions' },
    ];

    watch([currentPage, perPage, searchQuery, sort], () => {
      getevents();
    });

    const dataMeta = computed(() => {
      const localItemsCount = refCourseListTable.value ? refCourseListTable.value.localItems.length : 0;
      return {
        from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
        to: perPage.value * (currentPage.value - 1) + localItemsCount,
        of: totalevents.value,
      };
    });

    const meta = reactive({});

    getevents();

    const getEditorOptions = (editorRef) => ({
      theme: 'snow',
      modules: {
        toolbar: {
          container: [
            [{ header: '1' }, { header: '2' }, { font: [] }],
            [{ list: 'ordered' }, { list: 'bullet' }],
            ['bold', 'italic', 'underline'],
            [{ color: [] }, { background: [] }],
            [{ align: [] }],
            ['link', 'image'],
          ],
        },
      },
    });

    const formatDate = (dateString) => {
      if (!dateString) return 'N/A';
      const options = { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' };
      return new Date(dateString).toLocaleDateString(undefined, options);
    };

    return {
      getEditorOptions,
      tableColumns,
      eventsList,
      meta,
      getevents,
      perPageOptions,
      totalevents,
      currentPage,
      perPage,
      dataMeta,
      refCourseListTable,
      searchQuery,
      onRowSelected,
      selectedRow,
      isReplyModalVisible,
      replyForm,
      replyUploadedFiles,
      isReplyLoading,
      openReplyModal,
      handleReplyFileUpload,
      submitReply,
      markAsResolved,
      sort,
      SortArray,
      replyFormValidate,
      statusClass,
      buttonVariant,
      formatDate,
    };
  },
};
</script>

<style scoped>
.icon-img {
  width: 20px;
  height: 20px;
}

.bg-blue {
  background-color: blue;
  padding: 10px;
  border-radius: 6px;
}

.bg-green {
  background-color: green;
  padding: 10px;
  border-radius: 6px;
}

.bg-grey {
  background-color: grey;
  padding: 10px;
  border-radius: 6px;
}

.text-white {
  color: white;
}

.per-page-selector {
  /* width: 90px; */
}
.modal-content {
  border: none;
  width: 600px !important;
}
.ticket-create-wrapper {
  padding: 2rem;
}
</style>
